*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;

}
  

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin-block: 0;
}

ul[role='list'],
ol[role='list'] {
    list-style: none;
}

body {
    min-height: 100dvh;
    line-height: 1.5;
    margin: 0;
}

h1,
h2,
h3,
h4,
button,
input,
label {
    line-height: 1.1;
}

h1,
h2,
h3,
h4 {
    text-wrap: balance;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
    color: currentColor;
}

img,
picture {
    max-width: 100%;
    display: block;
}

input,
button,
textarea,
select {
    font: inherit;
}

button {
    background: none;
    border: none;
    color: inherit;
    padding: 0;
}

textarea:not([rows]) {
    min-height: 10em;
}

:target {
    scroll-margin-block: 5ex;
}