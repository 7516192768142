// lander customizations
body {
	background: var(--c-brand-blackish);
}

#resurrection {
	--_bg-color: var(--c-brand-blackish);
	--_content-color: var(--c-brand-whitish);
	--_section-gutter: 64px;
	--_section-spacing: calc(var(--_section-gutter) * 2);

	--_site-pad: 24px;
	color: var(--_content-color);
	padding: var(--_site-pad);
	position: relative;
	isolation: isolate;
	max-width: 100vw;
	overflow: clip;

	&::before {
		content: "";
		background: var(--_bg-color);
		position: fixed;
		inset: 0;
		z-index: -1;
	}

	@include bp(lap) {
		--_section-gutter: 40px;
	}

	@include bp(hands) {
		--_section-gutter: var(--_site-pad);
	}
}

// global masthead
#head {
	@include flex($justify: space-between, $gap: 16px);
	margin-bottom: var(--_section-spacing);
}

#hero {
	border-radius: var(--radius-small) var(--radius-small) var(--radius-big) var(--radius-big);
	background: var(--c-brand-charcoal);
	padding: 40px var(--_section-gutter);
	position: relative;
	isolation: isolate;
	overflow: hidden;
	z-index: 2;
	filter: drop-shadow(0 12px 40px black);

	.art {
		position: absolute;
		inset: 0;
		z-index: -1;
		align-items: end;

		img {
			position: absolute;
		}
	}
}

#heroClouds {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top left;
}

#heroFlame {
	bottom: 0;
	width: 38%;
	// filter: blur(1px);
	transform: translate(115%, -10%);
	mix-blend-mode: luminosity;
	opacity: 0.975;
}

#heroForeground,
#heroWiggler,
#heroDancer1,
#heroDancer2 {
	z-index: 1;
	bottom: 0;
}

#heroForeground {
	width: 100%;
}

#heroWiggler {
	width: 52%;
	transform: translate(70%);
	margin-bottom: -1px;
	animation: backAndForth 5000ms alternate-reverse infinite ease;
}

#heroDancer1,
#heroDancer2 {
	width: 36%;
	filter: blur(1px);
}

#heroDancer1 {
	transform: translate(200%, 15%);
	z-index: 0.5;
}

#heroDancer2 {
	transform: translate(75%, 0%);
}

#bigNav {
	@include flex($gap: 16px);
	font-variation-settings: "wght" var(--f-weight-heavy), "wdth" var(--f-width-normal);
	border-radius: var(--radius-small);
	background: var(--_bg-color);
	padding: 8px 8px 8px 16px;

	.active {
		text-decoration: underline;
		text-underline-offset: 8px;
	}

	.navItem {
		color: var(--c-brand-whitish);
		text-decoration: none;

		&:hover {
			color: var(--mix-charcoal);
		}
	}

	@include bp(hands) {
		padding: 0;
		background: none;

		a:not([class^="button"]) {
			display: none;
		}
	}
}

#intro {
	@include flex($direction: column, $align: flex-start, $gap: 40px);

	.copy p {
		max-width: 52ch;
	}
}

#burningTokens {
	@include flex($align: flex-end, $gap: var(--_site-pad));

	.token {
		filter: drop-shadow(0 12px 40px black);
		@for $i from 1 through 5 {
			&:nth-of-type(#{$i}) {
				flex: 1 + ($i - 1) * 0.125;
			}
		}
		@include bp(lap) {
			&:nth-of-type(1) {
				display: none;
			}
		}
		@include bp(hands) {
			&:nth-of-type(2) {
				display: none;
			}
		}
	}
}

#knowledge {
	@include flex(row, stretch, center, var(--_site-pad), wrap);
}

#timer {
	@include flex(row, center, flex-start, 12px, wrap);
	flex-basis: 100%;
	position: sticky;
	top: 0;
	z-index: 4;
	background: var(--c-brand-blackish);
	filter: drop-shadow(0 12px 40px black);

	.pair {
		color: rgba(255, 255, 255, 0.5);
		color: var(--mix-charcoal);
	}
	strong {
		color: var(--c-brand-red);
	}
}

#faq {
	width: 100%;
}

#action {
	@include flex(column, center, flex-end, 40px);
	background: var(--c-brand-red);
	padding: 64px 16vw;
	border-radius: var(--radius-small);
	text-align: center;
	position: relative;
	isolation: isolate;
	overflow: hidden;

	.art {
		position: absolute;
		inset: 0;
		z-index: -1;
	}

	.dancer {
		position: absolute;
		scale: -1 1;
		bottom: -7%;
	}

	#actionDancer2 {
		transform: translateX(15%);
		left: 0;
		width: 480px;
	}

	#actionDancer1 {
		transform: translateX(-10%);
		right: 0;
		width: 440px;
	}

	@include bp(hands) {
		padding-inline: var(--_site-pad);

		#actionDancer2 {
			transform: translateX(50%);
			width: 400px;
		}

		#actionDancer1 {
			transform: translateX(-50%);
			width: 360px;
		}
	}
	@include bp(hands) {
		padding-block: 40px;
	}
}