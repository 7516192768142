$q-hand: 520px;
$q-hands: 824px;
$q-lap: 1080px;
$q-desk: 1440px;

:root {
    // queries
    --q-hand: #{$q-hand};
    --q-hands: #{$q-hands};
    --q-lap: #{$q-lap};
    --q-desk: #{$q-desk};

    // fonts
    --f-scream: mudstone-sans, system-ui, sans-serif;
    --f-talk: allotrope-variable, system-ui, sans-serif;

    // brand colors
    --c-brand-charcoal: #222222;
    --c-brand-blackish: #0b0b0b;
    --c-brand-whitish: #F9F5F1;
    --c-brand-red: #ED4747;
    // gob colors
    --c-gob-whitish: #FCF7F1;
    --c-gob-orange: #FE6544;
    --c-gob-yellow-400: #E0BD42;
    --c-gob-yellow-200: #EBCA76;
    --c-gob-blue: #349DA4;
    // meme depot colors
    --c-meme-pink: #ee4be8;
    --c-meme-purp: #300037;
    // grumpl colors
    --c-cake-peach: #FF8282;
    --c-cake-purp: #701772;
    --c-cake-pink: #CD3BF1;
    // nooble colors
    --c-noob-blue: #63AED8;
    --c-noob-orange: #ED4747;
    --c-noob-yellow: #ECBB3D;
    --c-noob-tan: #9F8872;
    // spit spots colors
    --c-spit-yellow: #eced44;
    --c-spit-green-600: #496e30;
    --c-spit-green-400: #72ab4c;
    --c-spit-green-200: #c3dc73;

    // easing
    --ease-linear: linear(
    0, 0.2178 2.1%, 1.1144 8.49%,
    1.2959 10.7%, 1.3463 11.81%,
    1.3705 12.94%, 1.3726, 1.3643 14.48%,
    1.3151 16.2%, 1.0317 21.81%,
    0.941 24.01%, 0.8912 25.91%,
    0.8694 27.84%, 0.8698 29.21%,
    0.8824 30.71%, 1.0122 38.33%, 1.0357,
    1.046 42.71%, 1.0416 45.7%,
    0.9961 53.26%, 0.9839 57.54%,
    0.9853 60.71%, 1.0012 68.14%,
    1.0056 72.24%, 0.9981 86.66%, 1
  );

  // styles
  --radius-small: 8px;
  --radius-big: 24px; 
  --max-width: var(--q-desk);
  --f-weight-normal: 200;
  --f-weight-heavy: 450;
  --f-weight-heavier: 800;
  --f-width-normal: 100;
  --f-width-wide: 112;
  --f-width-narrow: 88;

  // mixing
  --mix-charcoal: color-mix(in lab, currentColor, var(--c-brand-charcoal));
}